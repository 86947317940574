import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { groupBy } from 'lodash';

import { format, useNotify, useModal } from '@moved/services';
import { AtomSpinner, Button, ExpandableCardList } from '@moved/ui';

import { ProviderCard } from './ProviderCard';
import { ProviderServiceCard } from './ProviderServiceCard';
import { SalesContactsModal } from './SalesContactsModal';

import CSS from './styles/ProviderServices.module.scss';

import { Screen } from '../../shared';

import {
  getProviders,
  useProviders,
  useProvidersPending,
} from '../actions/getProviders';
import {
  getProviderServices,
  useProviderServices,
  useProviderServicesPending,
} from '../actions/getProviderServices';
import {
  getProviderLink,
  useProviderLinkPending,
} from '../actions/getProviderLink';
import {
  submit,
  useSubmitCableInternetTaskPending,
} from '../actions/submit';

export const ProviderServices = ({
  nextScreen,
  screen,
  taskDefinition,
}) => {
  const { id } = useParams();
  const notify = useNotify();
  const modal = useModal();
  const dispatch = useDispatch();

  const buildingProviders = useProviders(id);
  const providerServices = useProviderServices(id);

  const providersPending = useProvidersPending();
  const servicesPending = useProviderServicesPending();
  const linkPending = useProviderLinkPending();
  const submitPending = useSubmitCableInternetTaskPending();

  useEffect(() => {
    dispatch(getProviders(id))
      .catch((err) => notify.error(format.error(err)));
    dispatch(getProviderServices(id))
      .catch((err) => notify.error(format.error(err)))
  }, [id, dispatch, notify]);

  const handleSubmit = () => {
    if (submitPending) return;
    dispatch(submit(id))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  const launchProviderLink = (provider, cta, order) => {
    const metadata = {
      origin: 'pricing',
      provider_order: order,
      cta_text: cta,
    };
    dispatch(getProviderLink(id, provider?.id, metadata))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)));
  };

  // Generates external link when user clicks CTA in InternetOfferCard
  const handleServiceClick = (service, order) => {
    const providerId = service?.provider?.id;
    if (providerId == null || linkPending) return;
    const buildingProvider = buildingProviders.find(provider => provider.id === providerId);
    const trackLink = () => launchProviderLink(service?.provider, 'Choose this plan', order);
    if (buildingProvider?.building_sales_contacts?.length > 0) {
      modal.open(
        <SalesContactsModal
          provider={buildingProvider}
          onClick={trackLink}
        />
      );
    } else trackLink();
  };

  const handleProviderClick = (provider) => {
    const providerId = provider?.id;
    if (providerId == null || linkPending) return;
    const buildingProvider = buildingProviders.find(provider => provider.id === providerId);
    const trackLink = () => launchProviderLink(provider, 'Shop this provider', 0);
    if (buildingProvider?.building_sales_contacts?.length > 0) {
      modal.open(
        <SalesContactsModal
          provider={buildingProvider}
          onClick={trackLink}
        />
      );
    } else trackLink();

  };

  if (servicesPending || providersPending) return <AtomSpinner />;

  const servicesByProvider = groupBy(providerServices, (d) => d.provider.id);

  const customBuildingProviders = (buildingProviders ?? []).filter(provider => {
    return !Object.keys(servicesByProvider).includes(String(provider.id));
  });

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <section className='stackVertical gap-28'>
          { customBuildingProviders.length > 0 && (
            <div className='stackVertical gap-12'>
              <div className='labelXS contentSecondary'>Community recommended</div>
              <div className='stackVertical gap-16'>
                {customBuildingProviders.map(provider => (
                  <ProviderCard
                    key={provider.id}
                    provider={provider}
                    onClick={provider => handleProviderClick(provider)}
                  />
                ))}
              </div>
            </div>
          )}
          <div className='stackVertical gap-12'>
            { customBuildingProviders.length > 0 && (
              <div className='labelXS contentSecondary'>Additional options</div>
            )}
            <div className='stackVertical gap-16'>
              {Object.entries(servicesByProvider).map(([providerId, providerServices], providerIndex) => (
                <ExpandableCardList
                  key={providerId}
                  hideCta='Hide plans'
                  showCta='View all plans'
                  className={CSS.cardList}
                  cardList={providerServices.map(service => (
                    <ProviderServiceCard
                      key={service.id}
                      service={service}
                      onClick={service => handleServiceClick(service,providerIndex)}
                    />
                  ))}
                />
              ))}
            </div>
          </div>
        </section>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Complete'
          size='large'
          onClick={handleSubmit}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
