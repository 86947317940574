import classNames from 'classnames';

import { format } from '@moved/services';
import { Button, Card } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common/index.js';

import CSS from './styles/ProviderCard.module.scss';

export const ProviderCard = ({
  provider,
  onClick,
  className,
}) => (
  <Card className={classNames(CSS.wrapper, className)}>

    <div className={classNames(CSS.item, CSS.vendor, 'stackVertical gap-8 items-start')}>
      <img
        className={CSS.logo}
        src={provider.logo_url}
        alt={provider.name}
      />
    </div>

    { provider?.description && (
      <div className={classNames(CSS.item, CSS.description)}>
        <ResidentContentRenderer content={provider?.description} className={CSS.content} />
      </div>
    )}

    <div className={classNames(CSS.item, CSS.cta)}>
      { provider?.phone != null && (
        <div className='stackVertical'>
          <label className='labelXS contentSecondary'>Call to order</label>
          <p className='labelM contentPrimary'>
            { format.phone(provider.phone) }
          </p>
        </div>
      )}
      <Button
        text='Shop this provider'
        onClick={() => onClick(provider)}
        className='width-full'
      />
    </div>

  </Card>
);
