import classNames from 'classnames';

import { useModal, format } from '@moved/services';
import { Button, Modal, Card, Icon } from '@moved/ui';

import CSS from './styles/SalesContactsModal.module.scss';

export const SalesContactsModal = ({
  provider,
  onClick,
}) => {
  const modal = useModal();
  return (
    <Modal style={{ maxWidth: '100%', width: '500px' }}>
      <Modal.Title>Order online or contact sales</Modal.Title>
      <Modal.Content className='stackVertical gap-24'>
        <div className='stackVertical gap-16'>
          { provider?.building_sales_contacts.map(contact => (
            <Card key={contact.id} className='stackVertical gap-12'>
              <div className='labelL contentPrimary'>{ contact.name }</div>
              <div className='stackVertical gap-4'>
                { contact.phone && (
                  <a
                    href={`tel:${contact.phone}`}
                    className={classNames('stackHorizontal gap-12 items-center',CSS.contactRow)}
                  >
                    <Icon
                      library='communication'
                      symbol='Call#1'
                    />
                    <div className='labelM contentPrimary flex-grow'>
                      { format.phone(contact.phone) }
                    </div>
                    <Icon
                      library='navigation'
                      symbol='Chevron-right'
                      color='gray'
                    />
                  </a>
                )}
                { contact.email && (
                  <a
                    href={`mailto:${contact.email}`}
                    className={classNames('stackHorizontal gap-12 items-center',CSS.contactRow)}
                  >
                    <Icon
                      library='communication'
                      symbol='Mail'
                    />
                    <div className='labelM contentPrimary flex-grow'>
                      { contact.email }
                    </div>
                    <Icon
                      library='navigation'
                      symbol='Chevron-right'
                      color='gray'
                    />
                  </a>
                )}
                { contact.contact_url && (
                  <a
                    href={contact.contact_url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classNames('stackHorizontal gap-12 items-center',CSS.contactRow)}
                  >
                    <Icon
                      library='home'
                      symbol='Globe'
                    />
                    <div className='labelM contentPrimary flex-grow'>
                      Website
                    </div>
                    <Icon
                      library='navigation'
                      symbol='Chevron-right'
                      color='gray'
                    />
                  </a>
                )}
              </div>
            </Card>
          ))}
        </div>
        <div className={classNames('labelXS contentSecondary',CSS.divider)}>or</div>
        <Button
          text='Order online'
          className='width-full'
          onClick={() => {
            onClick();
            modal.close();
          }}
        />
      </Modal.Content>
    </Modal>
  );
};
