import classNames from 'classnames';

import { format, useModal } from '@moved/services';
import { Button, Card, Icon, TruncateText } from '@moved/ui';

import { LegalDisclaimerModal } from './LegalDisclaimerModal.js';

import CSS from './styles/ProviderServiceCard.module.scss';

export const ProviderServiceCard = ({
  service,
  onClick,
}) => {
  const modal = useModal();

  const showTermsConditionsModal = (legalText) => {
    modal.open(
      <LegalDisclaimerModal legalText={legalText} />
    );
  };

  // Add cents if not included in price
  const formatPrice = (price='') => price.includes('.') ? price : `${price}.00`;

  return (
    <Card
      key={service.id}
      className={CSS.wrapper}
    >

      <div className={classNames(CSS.item, CSS.vendor, 'stackVertical gap-8 items-start')}>
        <img
          className={CSS.logo}
          src={service.provider.logo_url}
          alt={service.provider.name}
        />
        <span className={classNames(CSS.badge, 'labelS contentSecondary backgroundPrimary')}>
          { service.name ?? `Community recommended` }
        </span>
      </div>

      { service.download_speed && (
        <div className={classNames(CSS.item, CSS.speed)}>
          <h2 className='headingM contentPrimary'>
            {service.download_speed}
          </h2>
          <div className='labelS contentSecondary stackHorizontal gap-4'>
            <Icon
              size='16px'
              symbol='Angle-double-down'
              library='navigation'
              color='gray'
            />
            <span>Max download speed</span>
          </div>
        </div>
      )}

      { service.connection_type && (
        <div className={classNames(CSS.item, CSS.type)}>
          <h2 className='headingM contentPrimary'>
            {service.connection_type}
          </h2>
          <p className='labelS contentSecondary stackHorizontal gap-4'>
            <Icon
              size='16px'
              symbol='Wi-fi'
              library='devices'
              color='gray'
            />
            <span>Connection type</span>
          </p>
        </div>
      )}

      { service.price_description && (
        <div className={classNames(CSS.item, CSS.price)}>
          <h2 className='headingM contentPrimary stackHorizontal gap-4 items-center'>
            {formatPrice(service.price_description)}
            <span className='labelM paddingTop-4'>
              /mo
            </span>
            {service.legal_disclaimer_text && (
              <span style={{ cursor: 'pointer' }}>
                <Icon
                  size='20px'
                  symbol='Info-circle'
                  library='code'
                  onClick={() => showTermsConditionsModal(service.legal_disclaimer_text)}
                />
              </span>
            )}
          </h2>
          { service.price_disclaimer_text && (
            <TruncateText
              className={CSS.legalText}
              maxLength={120}
              text={service.price_disclaimer_text}
            />
          )}
        </div>
      )}

      <div className={classNames(CSS.item, CSS.cta)}>
        {service.provider?.phone != null && (
          <div className='stackVertical'>
            <label className='labelXS contentSecondary'>Call to order</label>
            <p className='labelM contentPrimary'>
              { format.phone(service.provider.phone) }
            </p>
          </div>
        )}
        <Button
          text='Choose this plan'
          onClick={() => onClick(service)}
          className='width-full'
        />
      </div>

    </Card>
  );
};
