import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Card, Modal, FieldList, Icon, Tooltip } from '@moved/ui';
import { useNotify, format, useUser, useModal, useRouter, useMobile } from '@moved/services';

import { setUhaulAccountOptInStatus } from '../actions/setUhaulAccountOptInStatus';

import image from './images/uhaul-apartment.jpg';
import CSS from './styles/UhaulSignupModal.module.scss';

export const UhaulSignupModal = ({ moveStep }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const modal = useModal();
  const { user } = useUser();
  const { params: { moveId }} = useRouter();

  const { building, lease, date } = moveStep;

  const setStatus = (optIn) => {
    dispatch(setUhaulAccountOptInStatus(moveId, {
      origin: 'mover_booking_task',
      opt_in: optIn,
      move_step_id: moveStep.id,
      mobile: isMobile,
     }))
      .then(result => optIn && window.open(decodeURIComponent(isMobile ? result?.account_url_mobile : result?.account_url_desktop)))
      .then(() => modal.close())
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Modal className={CSS.container}>
      <div className='stackHorizontal'>
        <div className={CSS.content}>
          <div className='stackVertical gap-4'>
            <h2 className='headingL contentPrimary'>
              Customized especially for {building?.settings?.display_name} residents!
            </h2>
            <p className='labelM contentSecondary'>
              Your community and Moved have partnered with U-Haul to make your
              move smarter, not harder. Secure your property-specific account
              today for fast, easy access to everything you'll need for moving day.
            </p>
          </div>
          <Card className='stackVertical gap-28 relative'>
            <Tooltip
              className={CSS.tooltip}
              tooltip='You can update your name or email address from your Moved profile.'
            >
              <Icon
                symbol='Info-circle'
                library='code'
              />
            </Tooltip>
            <div className='stackVertical gap-8'>
              <div className={CSS.customImage}>
                <Card className={classNames(CSS.iconCard, CSS.origin)}>
                  <Icon
                    symbol='Origin'
                    library='map'
                    color='blue'
                  />
                </Card>
                <Card className={classNames(CSS.iconCard, CSS.destination)}>
                  <Icon
                    symbol='Marker#1'
                    library='map'
                    color='blue'
                  />
                </Card>
                <div className={CSS.arc} />
                <div className='labelS contentSecondary txt-center'>
                  Moving address
                </div>
              </div>

              <h3 className='headingM contentPrimary txt-center'>
                { format.address(lease?.address, { unit: false }) }
              </h3>
              <FieldList
                list={[
                  {
                    icon: { symbol: 'Date-to', library: 'code', color: 'black' },
                    label: 'Move information',
                    value: format.date(date),
                  },
                  {
                    icon: { symbol: 'User', library: 'general', color: 'black' },
                    label: 'Name',
                    value: format.fullname(user),
                  },
                  {
                    icon: { symbol: 'Mail', library: 'communication', color: 'black' },
                    label: 'Email address',
                    value: user.email,
                  },

                ]}
              />
            </div>
          </Card>
          <div className='stackVertical gap-8 items-stretch'>
            <p className='labelM contentSecondary txt-center'>
              By clicking Yes you agree to U-Haul's{' '}
              <a href='https://www.uhaul.com/Arbitration/' rel='noopener noreferrer' target='_blank'>
                Terms of Use
              </a>{' '}
              and consent for Moved to provide your moving details to U-Haul.
            </p>
            <Button
              text='Yes, secure my U-Haul account'
              onClick={() => setStatus(true)}
            />
            <Button
              text={`No thanks, I'll do it myself`}
              onClick={() => setStatus(false)}
              color='secondary'
            />
          </div>
        </div>

        <div
          className={CSS.sideImage}
          style={{ backgroundImage: `url(${image})` }}
        />

      </div>
    </Modal>
  );
};
